import { useEffect, useState, ReactElement } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { postTrackingEvent, TrackingEventEnum } from '../../utils/analytics';
import useReduxKey from '../../hooks/useReduxKey';
import formActions from '../../actions/formActions';

import CheckoutHeader from './CheckoutHeader';
import SpaceStickyFooter from '../../components/SpaceStickyFooter';

import './Notes.scss';
import useAnalytics from '../../hooks/useAnalytics';
import { FilterState } from '../../reducers/filter';

export default function NotesComponent(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const { setNotes } = formActions();
  const { notes } = useReduxKey('details');
  const { showNotes } = useReduxKey('configuration');
  const [maxReached, setMaxReached] = useState(false);
  const { selectedSpace } = useReduxKey<FilterState>('filterState');
  const { trackEvent } = useAnalytics();

  const maximumNotesLength = 255;
  function validateAndSetFieldValue(value: string) {
    if (value.length > maximumNotesLength - 10) {
      setMaxReached(true);
    } else {
      setMaxReached(false);
    }
    if (value.length <= maximumNotesLength) {
      setNotes(value);
    }
  }

  function availableCharacters() {
    return `${maximumNotesLength - notes.length} / ${maximumNotesLength}`;
  }

  useEffect(() => {
    if (!showNotes) {
      navigate('/clientDetails', { state: { step: 2 }, replace: true });
    } else {
      trackEvent(TrackingEventEnum.SCHEDULE_NOTES);
    }
  }, [showNotes, navigate, trackEvent]);

  if (!selectedSpace) {
    return <span>{t('coming_up')}</span>;
  }

  return (
    <>
      <CheckoutHeader step={1} />
      <div className="clientNotes-container">
        <h2>{t('notes')}</h2>
        <p>{t('other_wishes')}</p>
        <div className="textarea-container">
          <textarea
            id="clientNotes-input"
            name="clientNotes"
            onChange={(event) => validateAndSetFieldValue(event.target.value)}
            defaultValue={notes}
            placeholder={t('request_and_comments')}
            rows={12}
            maxLength={255}
          />
          <span
            className={
              maxReached ? 'available-chars warning' : 'available-chars'
            }
          >
            {t('available_characters:')}
            {availableCharacters()}
          </span>
        </div>
      </div>
      <SpaceStickyFooter navigate={navigate} next="/clientDetails" nextStep={2} />
    </>
  );
}