import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import useReduxKey from '../../hooks/useReduxKey';
import formActions from '../../actions/formActions';

import CheckoutHeader from './CheckoutHeader';
import InputField from '../../components/InputField/InputField';
import SpaceStickyFooter from '../../components/SpaceStickyFooter';

import { TrackingEventEnum } from '../../utils/analytics';

import './Details.scss';
import useAnalytics from '../../hooks/useAnalytics';
import { RootState } from '../../reducers';
import { CustomField } from '../../reducers/customFields';
import { customFieldValidator } from '../../utils/customFields';

function addCustomFields(customFields, initialValues, setInitialValues) {
  if (customFields) {
    const values = customFields.reduce((obj, customField) => ({ ...obj, [customField.salesforce_field_name]: customField.default_value ?? '' }), {});

    const newInitialValues = { ...initialValues, ...values };
    setInitialValues(newInitialValues);
  }
}

function useValidation(customFields: CustomField[], requiredFieldMessage: string,
  invalidFieldMessage: string, showCompanyInputField: boolean) {
  return useMemo(
    () =>
      Yup.object().shape<Record<string, any>>(
        (customFields || []).reduce(
          (schema, {
            type,
            required,
            hidden,
            salesforce_field_name: salesforceFieldName,
          }) => {
            const validator = customFieldValidator({ type, required, hidden }, requiredFieldMessage);

            if (validator) {
              return {
                ...schema,
                [salesforceFieldName]: validator,
              };
            }
            return schema;
          },
          {
            /* Default field validations */
            firstName: Yup.string().required(requiredFieldMessage),
            lastName: Yup.string().required(requiredFieldMessage),
            email: Yup.string().email(invalidFieldMessage).required(requiredFieldMessage),
            company: showCompanyInputField ? Yup.string().nullable() : undefined,
          },
        ),
      ),
    [customFields],
  );
}

export default function Details(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { details } = useSelector(
    (rootState: RootState) => rootState,
  );
  const {
    showCompanyInputField,
    contactId,
    leadId,
    accountId,
    customFields,
  } = useReduxKey(
    'configuration',
  );

  const formRef = useRef(null);
  const { setDetails } = formActions();
  const [initialValues, setInitialValues] = useState(details.form);
  const { trackEvent } = useAnalytics();

  const invalidFieldMessage = t('invalid_value', 'Invalid value');
  const requiredFieldMessage = t('required_field', 'Required field');

  const { state } = useLocation();
  useEffect(() => {
    if (contactId !== undefined || leadId !== undefined || accountId !== undefined) {
      navigate('/attendeeDetails', { state: { step: state.step }, replace: true });
    }
  }, [contactId, leadId, accountId]);

  const handleSubmit = (values) => {
    setDetails(values);
    navigate('/attendeeDetails', { state: { step: state.step + 1 } });
  };

  const fireSubmitAction = async () => {
    if (formRef.current) {
      await formRef.current.validateForm();

      if (!formRef.current.isValid) {
        // Render form errors manually
        const { errors } = formRef.current;

        formRef.current.setTouched(errors, true);
      } else {
        formRef.current.handleSubmit();
      }
    }
  };

  useEffect(() => {
    addCustomFields(customFields, initialValues, setInitialValues);
    trackEvent(TrackingEventEnum.SCHEDULE_DETAILS);
  }, []);

  const validationSchema = useValidation(customFields, requiredFieldMessage,
    invalidFieldMessage, showCompanyInputField,
  );

  return (
    <>
      <CheckoutHeader step={state.step} />
      <div className="form-container">
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit} className="details_form">
              <div className="client-contact-container">
                <div className="client-details">
                  <h2>{t('client_details')}</h2>
                  <p>{t('billing_details')}</p>

                  <InputField
                    required
                    type="text"
                    name="firstName"
                    label={t('first_name')}
                    placeholder={t('first_name')}
                  />
                  <InputField
                    required
                    type="text"
                    name="lastName"
                    label={t('last_name')}
                    placeholder={t('last_name')}
                  />
                  {showCompanyInputField && (
                    <InputField
                      type="text"
                      name="company"
                      label={t('company')}
                      placeholder={t('company')}
                    />
                  )}
                </div>

                <div className="contact-details">
                  <h2>{t('contact_details')}</h2>
                  <p>{t('confirmation_email_details')}</p>

                  <InputField
                    required
                    type="email"
                    name="email"
                    label={t('email_address')}
                    placeholder={t('email_address')}
                  />
                </div>

                {(customFields || []).map((customField) => (
                  <InputField
                    name={customField.salesforce_field_name}
                    key={customField.salesforce_field_name}
                    required={!customField.hidden && customField.required}
                    type={customField.type}
                    hidden={customField.hidden}
                    label={t(`custom_field___${customField.salesforce_field_name.toLowerCase()}`, customField.label).toString()}
                  />
                ))}
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <SpaceStickyFooter navigate={navigate} next="/attendeeDetails" nextStep={state.step + 1} actionCallback={fireSubmitAction} />
    </>
  );
}
