import { createContext, useCallback, useEffect, useState, useContext } from 'react';
import useReduxKey from '../hooks/useReduxKey';
import { PriceBreakdown, ServicePrototype} from '../types';
import useBooker25 from '../hooks/useBooker25';
import { FilterState } from '../reducers/filter';
import { Services } from '../reducers/services';
import getReservationData from '../utils/booking';
import { LanguageContext } from '../contexts/LanguageProvider';

interface ServicesTotals {
  servicesSubtotal?: number
  servicesSubtotalIncl?: number
}

interface PriceContextApi extends Partial<PriceBreakdown & ServicesTotals> {
  getPrice: (abortSignal?: AbortSignal) => Promise<void>
}

export const PriceContext = createContext<Partial<PriceContextApi>>({});

export default function PriceProvider({ children }): JSX.Element {
  const {
    reservationType,
    selectedSpace,
    groupSize,
    selectedTimeSlot,
  } = useReduxKey<FilterState>('filterState');
  const {
    includedServices,
    additionalServices,
  } = useReduxKey<Services>('services');

  const {
    showPayments,
    accountId,
    leadId,
    contactId
  } = useReduxKey('configuration');
  const customFields = useReduxKey('details');
  const attendeeDetails = useReduxKey('attendees');
  const filterState = useReduxKey<FilterState>('filterState');
  const { language } = useContext(LanguageContext);

  const booker25 = useBooker25();

  const [priceBreakdown, setPriceBreakdown] = useState<Partial<PriceBreakdown>>({});
  const [servicesTotals, setServicesTotals] = useState<Partial<ServicesTotals>>({});

  const getPrice = useCallback((abortSignal?: AbortSignal) => {
    const reservationData = buildReservationData();

    return booker25
      .getPriceBreakdown(
        reservationData,
        abortSignal)
      .then(setPriceBreakdown)
      .catch((e) => {
        if (!(e instanceof DOMException && e.name === 'AbortError')) {
          throw e;
        }
      })
    }, [
    reservationType,
    selectedTimeSlot,
    selectedSpace,
    groupSize,
    includedServices,
    additionalServices,
    showPayments,
    customFields
  ]);

  useEffect(() => {
    const abortController = new AbortController();
    if (selectedTimeSlot !== null && selectedSpace !== null && showPayments) {
      getPrice(abortController.signal);
    }

    return () => {
      abortController.abort();
    };
  }, [
    reservationType,
    selectedTimeSlot,
    selectedSpace,
    groupSize,
    includedServices,
    additionalServices,
    showPayments,
    customFields
  ]);

  useEffect(() => {
    priceBreakdown.services !== undefined && setServicesTotals({
      servicesSubtotal: priceBreakdown.services
        .reduce((total, { subtotal }) => (
          total + subtotal
        ), 0),
      servicesSubtotalIncl: priceBreakdown.services
        .reduce((total, { subtotal_incl: subtotalIncl}) => (
          total + subtotalIncl
        ), 0),
    })
  }, [priceBreakdown]);

  const buildReservationData = () => {
    const reservationData = {
      language,
      ...getReservationData(
        filterState, contactId, leadId, accountId, customFields, attendeeDetails,
      ),
    };

    reservationData.resource_id = selectedSpace.id;
    reservationData.start_date = selectedTimeSlot.dStart.toISOString()
    reservationData.end_date = selectedTimeSlot.dEnd.toISOString()
    reservationData.reservation_type = reservationType
    reservationData.quantity = groupSize
    reservationData["services"] = [...includedServices ?? [], ...additionalServices ?? []]
      .filter(({ selectedQuantity }) => selectedQuantity > 0)
      .map<ServicePrototype>(({
        id,
        selectedQuantity,
      }) => ({
        id,
        quantity: selectedQuantity,
      }))

    return reservationData;
  };

  return (
    <PriceContext.Provider
      value={{
        ...priceBreakdown,
        ...servicesTotals,
        getPrice,
      }}
    >
      {children}
    </PriceContext.Provider>
  );
}
